import { Vue, Component } from "vue-property-decorator";
import { AxiosInstance, AxiosResponse, AxiosError } from "axios";

/**
 * v-data-tableのフィルタMixin
 */
@Component
export default class extends Vue {
  /** ローディングフラグ */
  protected loginLoading = false;

  /** ワンタイム認証（ユーザー） */
  protected userOnetime(
    baseUrl: string,
    axios: AxiosInstance,
    token: string,
    setToken: (res: AxiosResponse) => void
  ) {
    this.loginLoading = true;
    axios
      .post(baseUrl + "/auth/user-onetime-login", {
        onetime_token: token
      })
      .then((res: AxiosResponse) => {
        this.loginLoading = false;
        setToken(res);
      })
      .catch(async () => {
        this.loginLoading = false;
      });
  }

  /** ワンタイム認証（管理者） */
  protected adminOnetime(
    baseUrl: string,
    axios: AxiosInstance,
    token: string,
    setToken: (res: AxiosResponse) => void
  ) {
    this.loginLoading = true;
    axios
      .post(baseUrl + "/auth/onetime-login", {
        onetime_token: token
      })
      .then((res: AxiosResponse) => {
        this.loginLoading = false;
        setToken(res);
      })
      .catch(async () => {
        this.loginLoading = false;
      });
  }
}
